import React from 'react';

import ArticleContent from './ArticleContent';
import ArticleHeader from './ArticleHeader';
import ArticleParagraph from './ArticleParagraph';
import ArticleSummary from './ArticleSummary';
import ArticleTitle from './ArticleTitle';

const Article: React.FC = ({ children }) => (
  <article>
    {children}
  </article>
);

export {
  ArticleHeader,
  ArticleTitle,
  ArticleContent,
  ArticleParagraph,
  ArticleSummary,
};
export default Article;

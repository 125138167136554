import React from 'react';

import styles from './ArticleParagraph.module.scss';

const ArticleParagraph: React.FC = ({ children }) => (
  <p className={styles.paragraph}>
    {children}
  </p>
);

export default ArticleParagraph;

import React from 'react';

import styles from './ArticleSummary.module.scss';

const ArticleSummary: React.FC = ({ children }) => (
  <p className={styles.summary}>
    {children}
  </p>
);

export default ArticleSummary;

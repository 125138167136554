import React from 'react';

import styles from './ArticleTitle.module.scss';

interface ArticleTitleProps {
  level: 1 | 2 | 3;
}

const ArticleTitle: React.FC<ArticleTitleProps> = ({ children, level }) => {
  switch (level) {
    case 1:
      return <h1 className={styles[`title${level}`]}>{children}</h1>;
    case 2:
      return <h2 className={styles[`title${level}`]}>{children}</h2>;
    case 3:
      return <h3 className={styles[`title${level}`]}>{children}</h3>;
    default:
      return <h1 className={styles[`title${level}`]}>{children}</h1>;
  }
};

export default ArticleTitle;

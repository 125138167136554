import React from 'react';

import styles from './ArticleHeader.module.scss';

const ArticleHeader: React.FC = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      {children}
    </div>
  </div>
);

export default ArticleHeader;

import React from 'react';

import styles from './ArticleContent.module.scss';

const ArticleContent: React.FC = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.article}>
      {children}
    </div>
  </div>
);

export default ArticleContent;
